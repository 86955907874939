import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import axios from "axios";
import VueAxios from "vue-axios";
import Toasted from "vue-toasted";
import Highcharts from "highcharts";
import VueHighcharts from "vue-highcharts";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import JsonExcel from "vue-json-excel";
import VueFilterDateFormat from 'vue-filter-date-format';
import vueNumeralFilterInstaller from 'vue-numeral-filter';

import HighchartsVue from "highcharts-vue";
import loadDrilldown from "highcharts/modules/drilldown";
import loadHighchartsMore from "highcharts/highcharts-more";
import loadSolidGauge from "highcharts/modules/solid-gauge";
import loadExporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";
import loadOfflineExporting from "highcharts/modules/offline-exporting";
import loadBoost from "highcharts/modules/boost";
import loadStock from "highcharts/modules/stock";
import loadMap from "highcharts/modules/map";

loadStock(Highcharts);
loadMap(Highcharts);
loadDrilldown(Highcharts);
loadHighchartsMore(Highcharts);
loadSolidGauge(Highcharts);
loadExporting(Highcharts);
loadOfflineExporting(Highcharts);

Vue.use(VueHighcharts, { Highcharts: Highcharts });
// load up the boost modules
loadBoost(Highcharts);
ExportData(Highcharts);
Vue.use(HighchartsVue);
Vue.component('v-select', vSelect);
Vue.component("downloadExcel", JsonExcel);
Vue.use(require('vue-moment'));
Vue.use(vueNumeralFilterInstaller);



Vue.config.productionTip = false;

window.axios = axios;
// axios.defaults.baseURL = "http://localhost:8000";
axios.defaults.baseURL = "https://api.hefrias.ng";
axios.defaults.headers.common["Content-Type"] = "application/json";
// axios.defaults.headers.common["ngrok-skip-browser-warning"] = "true"; 

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("jwt");

new Vue({
  router,
  store,
  axios,
  VueAxios,
  Toasted,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(Toasted);
